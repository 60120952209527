import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { windowToken } from '@app/shared/window/window.service';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { LoggerService } from '../logger';

import { httpStatus } from '@app/utils';
import { environment } from '@environments/environment';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { FeatureFlagNames } from '../feature-flag/shared/feature-flag.type';

@Injectable()
export class ForbiddenInterceptorService implements HttpInterceptor {
  // api/v2/admin/profile should not be here
  restrictedEndpointPatterns: RegExp[] = [
    new RegExp(/\/api\/v2\/admin\/patients\/\d+$/),
  ];

  constructor(
    private logger: LoggerService,
    private launchDarklyService: LaunchDarklyService,
    @Inject(windowToken) private window: Window,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.launchDarklyService.initialized$.pipe(
          first(Boolean),
          map(() => {
            const enableCallToOmacs = this.launchDarklyService.variation(
              FeatureFlagNames.enableCallToOmacs,
              false,
            );
            const allowMultipleApisToCallOmacs =
              this.launchDarklyService.variation(
                FeatureFlagNames.allowMultipleApisToCallOmacs,
                false,
              );
            const enabled = enableCallToOmacs || allowMultipleApisToCallOmacs;
            const forbidden = error.status === httpStatus.FORBIDDEN;
            const restricted = this.isRestrictedEndpoint(request.url);
            const relevant = !request.params.has('ignore403');
            const checks: boolean[] = [
              enabled,
              forbidden,
              restricted,
              relevant,
            ];
            const redirect = checks.every(Boolean);
            if (redirect) {
              const patientId = request.url.split('/').pop();
              const destination = `${environment.memberManagementUi.host}/members/restricted/${patientId}/access_denied`;
              this.logger.info('Rerouting to CAZ workflow error: ' + error);
              this.window.location.href = destination;
            }
            throw error;
          }),
        );
      }),
    );
  }

  /**
   * For now we only want to redirect users to CAZ for /api/v2/admin/patients/<id>
   * This will need to be updated as we expand to other endpoints.
   */
  private isRestrictedEndpoint(endpoint: string): boolean {
    const validUrl = URL.canParse(endpoint);
    if (!validUrl) {
      return false;
    }
    const url = new URL(endpoint);
    return this.restrictedEndpointPatterns.some(pattern =>
      pattern.test(url.pathname),
    );
  }
}
